/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */


import React, { useState, useEffect, useRef } from 'react';
import { storage, firestore, auth } from '../firebase'; // Importe o módulo de armazenamento, Firestore e autenticação
import { Dropdown } from 'react-bootstrap'; // Importe o componente de dropdown do Bootstrap
import '../styles/MyComponent.css';
import logo from '../img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUserId } from '../auth';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { faCamera } from '@fortawesome/free-solid-svg-icons'; // 

function MyComponent({ projectName, companyName, status, startDate, endDate, address, phone }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [documents, setDocuments] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Estado para controlar se o dropdown está aberto ou fechado
  const dropdownRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const inputFileRef = useRef(null);

  useEffect(() => {
    fetchUserDocuments();
  }, []);

  const fetchUserDocuments = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userDocumentsRef = firestore.collection('user_documents');
        const snapshot = await userDocumentsRef.where('userId', '==', userId).get();
        const userDocuments = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDocuments(userDocuments);
      } else {
        console.error('Nenhum usuário autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar documentos do usuário:', error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const projectRef = firestore.collection('projects').doc('PROJECT_ID'); // Substitua 'PROJECT_ID' pelo ID do documento do projeto no Firestore
      const projectSnapshot = await projectRef.get();
      if (projectSnapshot.exists) {
        const data = projectSnapshot.data();
        setProjectData(data);
      } else {
        console.error('Nenhum documento de projeto encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do projeto:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleCameraButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // Aciona o clique no input de arquivo
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    console.log('Usuário deslogado!');
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleUpload = async () => {
    try {
      if (file && fileName.trim() !== '') {
        // Crie uma referência no Firebase Storage para armazenar o arquivo
        const storageRef = storage.ref();
        const fileRef = storageRef.child(fileName); // Use o nome fornecido pelo usuário
        // Faça o upload do arquivo para o Firebase Storage
        await fileRef.put(file);
        // Obtenha o URL do arquivo após o upload
        const url = await fileRef.getDownloadURL();
        // Salve o URL do arquivo no Firestore
        await saveFileUrlToFirestore(url);
        alert('Arquivo enviado com sucesso!');
        setFile(null); // Limpe o estado do arquivo
        setFileName(''); // Limpe o estado do nome do arquivo
        fetchUserDocuments(); // Atualize a lista de documentos
      } else {
        alert('Por favor, selecione um arquivo PDF e forneça um nome.');
      }
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
      alert('Erro ao enviar arquivo. Por favor, tente novamente.');
    }
  };

  const saveFileUrlToFirestore = async (url) => {
    try {
      // Salve o URL do arquivo no Firestore associado ao usuário logado
      const userId = auth.currentUser.uid; // Obtenha o ID do usuário logado
      await firestore.collection('user_documents').add({
        userId: userId,
        fileName: fileName, // Salve o nome do arquivo fornecido pelo usuário
        url: url,
      });
    } catch (error) {
      console.error('Erro ao salvar URL do arquivo no Firestore:', error);
      throw error;
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Alterna entre aberto e fechado ao clicar no botão
  };

  return (
    <div className="min-h-screen bg-gray-100">

<nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-8"
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="flex md:hidden">
              <button
                onClick={toggleMobileMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-expanded={isMobileMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${isMobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
                <svg
                  className={`${isMobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/dashboard" className="text-white px-3 py-2 rounded-md text-sm font-medium">Home</Link>
                <Link to="/new-project" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Novo Projeto</Link>
                <Link to="/projects" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Projetos</Link>
                <Link to="/trello-board" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Tarefas</Link>
                <Link to="/" onClick={handleLogout} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"><FaSignOutAlt className="mr-1" />Sair</Link> {/* Adicione o nome do escritório aqui */}
                <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"><FontAwesomeIcon icon={faUserCircle} className="avatar-icon" /><span></span></div>    
                <p className='version-nav' >Versão Beta: 0.0.1-beta</p>  
              </div>
            </div>
          </div>
        </div>
        <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/dashboard" onClick={closeMobileMenu} className="text-white block px-3 py-2 rounded-md text-base font-medium">Home</Link>
            <Link to="/new-project" onClick={closeMobileMenu} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Novo Projeto</Link>
            <Link to="/projects" onClick={closeMobileMenu} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projetos</Link>
            <Link to="/notes" onClick={closeMobileMenu} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Notas</Link>
            <Link to="/" onClick={handleLogout} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"><FaSignOutAlt className="mr-1" />Sair</Link> {/* Adicione o nome do escritório aqui */}
            <p className='version-nav'>Versão Beta: 0.0.1-beta</p>
            </div>
            </div>
            </nav>
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                  <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                </div>
              </header>


              <div className="container-document">
  <input
    type="text"
    placeholder="Nome do arquivo"
    value={fileName}
    onChange={handleFileNameChange}
    className="document-base"
  />
      <button className="purple-button-pdf" onClick={handleCameraButtonClick}>
      Selecionar Documento
    </button>
  <button className="auth-button-1" onClick={handleUpload}>Enviar</button>
  <div className="input-group-cam camera-input">
    <input
      ref={inputFileRef}
      type="file"
      id="itemImage"
      name="itemImage"
      onChange={handleFileChange}
      style={{ display: 'none' }}
    />

  </div>

  {/* Card dropdown para exibir documentos */}
  <div className="dropdown-container">
    <button className="dropdown-button" onClick={toggleDropdown}>
      Documentos e arquivos da obra
    </button>
    {dropdownOpen && (
      <div>
        {documents.map((document) => (
          <a
            className="dropdown-content-pdf"
            key={document.id}
            href={document.url}
            target="_blank"
          >
            {document.fileName}
          </a>
        ))}
      </div>
    )}
  </div>
</div>

    </div>
  );
}

export default MyComponent;