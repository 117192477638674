import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import '../styles/TrelloBoard.css';
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUserId } from '../auth';

const TrelloBoard = () => {
  const [columns, setColumns] = useState(() => {
    const storedColumns = localStorage.getItem('columns');
    return storedColumns ? JSON.parse(storedColumns) : [];
  });
  const [userId, setUserId] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [editedCard, setEditedCard] = useState(null);
  const [editedCardContent, setEditedCardContent] = useState({});
  const [officeName, setOfficeName] = useState('');
  const currentUser = auth.currentUser;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      }
    });
    fetchColumns();
    return () => unsubscribe();
  }, []);

  const handleDragStart = (e, cardId, columnId) => {
    e.dataTransfer.setData('cardId', cardId);
    e.dataTransfer.setData('columnId', columnId);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    console.log('Usuário deslogado!');
  };

// Exemplo de uso consistente de async/await
const fetchOfficeName = async () => {
  try {
    const userId = getCurrentUserId();
    const userDoc = await firestore.collection('users').doc(userId).get();
    const userData = userDoc.data();
    // Atualiza o estado local com o nome do escritório
    setOfficeName(userData.officeName);
  } catch (error) {
    console.error('Erro ao buscar nome do escritório:', error);
    // Exibir uma mensagem de erro para o usuário, se necessário
  }
};


  useEffect(() => {
    fetchOfficeName();
  }, []);

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = async (e, targetColumnId) => {
    e.preventDefault();
    const cardId = e.dataTransfer.getData('cardId');
    const sourceColumnId = e.dataTransfer.getData('columnId');

    if (sourceColumnId !== targetColumnId) {
      try {
        const sourceColumnRef = firestore.collection('columns').doc(sourceColumnId);
        const targetColumnRef = firestore.collection('columns').doc(targetColumnId);

        const sourceColumnSnapshot = await sourceColumnRef.get();
        const targetColumnSnapshot = await targetColumnRef.get();

        const sourceColumn = sourceColumnSnapshot.data();
        const targetColumn = targetColumnSnapshot.data();

        const cardToMove = sourceColumn.cards.find(card => card.id === cardId);
        const updatedSourceCards = sourceColumn.cards.filter(card => card.id !== cardId);
        const updatedTargetCards = [...targetColumn.cards, cardToMove];

        await sourceColumnRef.update({ cards: updatedSourceCards });
        await targetColumnRef.update({ cards: updatedTargetCards });

        fetchColumns();
      } catch (error) {
        console.error('Erro ao mover tarefa:', error);
      }
    }
  };

  const handleAddCard = async (columnId, title, description) => {
    try {
      const newCard = {
        id: Math.random().toString(),
        title,
        description,
        createdDate: new Date().toLocaleDateString(),
        userId: userId
      };
  
      const columnRef = firestore.collection('columns').doc(columnId);
      const columnSnapshot = await columnRef.get();
      const columnData = columnSnapshot.data();
  
      if (!columnData) {
        throw new Error('Coluna não encontrada.');
      }
  
      const updatedCards = [...columnData.cards, newCard];
      await columnRef.update({ cards: updatedCards });
  
      // Atualiza o estado local com as colunas atualizadas
      fetchColumns();
    } catch (error) {
      console.error('Erro ao adicionar tarefa:', error);
      // Exibir uma mensagem de erro para o usuário
      // Por exemplo: setErrorMessage('Erro ao adicionar tarefa. Tente novamente.');
    }
  };
  
  const fetchColumns = async () => {
    try {
      const columnsRef = firestore.collection('columns');
      const snapshot = await columnsRef.get();
      const fetchedColumns = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredColumns = fetchedColumns.map(column => ({
        ...column,
        cards: column.cards.filter(card => card.userId === currentUser.uid)
      }));

      setColumns(filteredColumns);
      localStorage.setItem('columns', JSON.stringify(filteredColumns)); // Salvar no localStorage
    } catch (error) {
      console.error('Erro ao buscar colunas:', error);
    }
  };

  const toggleDropdown = cardId => {
    setOpenDropdown(openDropdown === cardId ? null : cardId);
  };

  const handleEditCard = cardId => {
    const cardToEdit = columns.flatMap(column => column.cards).find(card => card.id === cardId);
    setEditedCard(cardId);
    setEditedCardContent(cardToEdit);
    toggleDropdown(cardId);
  };

  const handleSaveEdit = async () => {
    try {
      const { id } = editedCardContent;
      const columnId = columns.find(column => column.cards.some(card => card.id === id)).id;
      const columnIndex = columns.findIndex(column => column.cards.some(card => card.id === id));
      const cardIndex = columns[columnIndex].cards.findIndex(card => card.id === id);

      const columnRef = firestore.collection('columns').doc(columnId);
      const columnSnapshot = await columnRef.get();
      const columnData = columnSnapshot.data();
      const updatedCards = [...columnData.cards];
      updatedCards[cardIndex] = editedCardContent;

      await columnRef.update({ cards: updatedCards });
      fetchColumns();
      setEditedCard(null);
      setEditedCardContent({});
    } catch (error) {
      console.error('Erro ao salvar edição:', error);
    }
  };

  const handleDeleteCard = async cardId => {
    try {
      const columnId = columns.find(column => column.cards.some(card => card.id === cardId)).id;
      const columnRef = firestore.collection('columns').doc(columnId);
      const columnSnapshot = await columnRef.get();
      const columnData = columnSnapshot.data();
      const updatedCards = columnData.cards.filter(card => card.id !== cardId);

      await columnRef.update({ cards: updatedCards });
      fetchColumns();
    } catch (error) {
      console.error('Erro ao excluir tarefa:', error);
    }
  };

  return (

    
    <div className="min-h-screen bg-gray-100">
      

            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                  <h1 className="text-3xl font-bold text-gray-900">Tarefas</h1>
                  <p className="text-gray-500">Arraste as tarefas para as colunas desejadas</p> {/* Texto informativo */}
                </div>
              </header>

      <div className="board" onDragOver={handleDragOver}>
        {columns.map(column => (
          <div
            key={column.id}
            className="column"
            onDrop={e => handleDrop(e, column.id)}
            onDragOver={handleDragOver}
          >
            <div className="column-title">{column.title}</div>
            <div className="cards">
              {column.cards.map(card => (
                <div
                  key={card.id}
                  className="card"
                  draggable
                  onDragStart={e => handleDragStart(e, card.id, column.id)}
                >
                  {editedCard === card.id ? (
                    <form onSubmit={handleSaveEdit}>
                      <input
                        type="text"
                        value={editedCardContent.title}
                        onChange={e =>
                          setEditedCardContent({ ...editedCardContent, title: e.target.value })
                        }
                      />
                      <textarea
                        value={editedCardContent.description}
                        onChange={e => setEditedCardContent({ ...editedCardContent, description: e.target.value })}
                      />
                    </form>
                  ) : (
                    <>
                      <div>{card.title}</div>
                      <div>{card.description}</div>
                      <div className='date-status'>Criado em: {card.createdDate}</div>
                      <div className="dropdown">
                        <button className="dropdown-btn" onClick={() => toggleDropdown(card.id)}>
                          &#8942;
                        </button>
                        {openDropdown === card.id && (
                          <div className="dropdown-content">
                            <button onClick={() => handleEditCard(card.id)}>Editar</button>
                            <button onClick={() => handleDeleteCard(card.id)}>Excluir</button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            <form
              onSubmit={async e => {
                e.preventDefault();
                const title = e.target.title.value;
                const description = e.target.description.value;
                await handleAddCard(column.id, title, description);
                e.target.reset();
              }}
            >
              <input className="input-task-title" type="text" name="title" placeholder="Título" required />
              <textarea className="input-task" name="description" placeholder="Descrição" required></textarea>
              <button className="button-task" type="submit">
                Adicionar Tarefa
              </button>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrelloBoard;
