import React, { useState, useEffect } from 'react';
import { addProject } from '../firebase';
import { getCurrentUserId } from '../auth';
import '../styles/NewProjectPage.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { firestore, auth } from '../firebase';

function NewProjectPage() {
  const [projectName, setProjectName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [phone, setPhone] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [projectScope, setProjectScope] = useState('');
  const [notification, setNotification] = useState('');
  const [officeName, setOfficeName] = useState('');

  useEffect(() => {
    fetchOfficeName();
  }, []);

  const fetchOfficeName = async () => {
    try {
      const userId = getCurrentUserId();
      const userDoc = await firestore.collection('users').doc(userId).get();
      const userData = userDoc.data();
      setOfficeName(userData.officeName);
    } catch (error) {
      console.error('Erro ao buscar nome do escritório:', error);
    }
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleAddProject = async () => {
    try {
      if (!projectName) {
        setNotification('Por favor, preencha o nome do projeto.');
        return;
      }
      
      const userId = getCurrentUserId();
      const projectData = {
        userId,
        projectName,
        companyName,
        cnpj,
        phone,
        startDate,
        endDate,
        status,
        cep,
        address,
        number,
        complement,
        neighborhood,
        state,
        city,
        projectScope
      };

      await addProject(projectData);
      setNotification('Projeto adicionado com sucesso!');
      clearForm();
    } catch (error) {
      console.error('Erro ao adicionar projeto:', error);
      setNotification('Erro ao adicionar projeto. Por favor, tente novamente.');
    }
  };

  const clearForm = () => {
    setProjectName('');
    setCompanyName('');
    setCnpj('');
    setPhone('');
    setStartDate(null);
    setEndDate(null);
    setStatus('');
    setCep('');
    setAddress('');
    setNumber('');
    setComplement('');
    setNeighborhood('');
    setState('');
    setCity('');
    setProjectScope('');
  };

    const handleInputChange = (e, setValue) => {
    const { value } = e.target;
    const onlyNumbers = value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
    setValue(onlyNumbers);
  };


  const formatCnpjCpf = (value) => {
    const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (cleaned.length === 11) {
      return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (cleaned.length === 14) {
      return cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    return value;
  };

  const handleCnpjChange = (e) => {
    const formattedValue = formatCnpjCpf(e.target.value);
    setCnpj(formattedValue);
  };

  return (
    <div className="min-h-screen bg-gray-100">






      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"> 
          <h1 className="text-3xl font-bold text-gray-900">Novo Projeto</h1>
        </div>
      </header>
      <main>
        {notification && (
          <div className={`notification ${notification.includes('Erro') ? 'error' : 'success'}`}>
            {notification}
          </div>
        )}

        <form className="form-container">
          <h3>CLIENTE</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
            <div className="form-group">
              <label htmlFor="companyName">Nome <span className="input-error-message">{!companyName && '*'}</span></label>
              <input
                type="text"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="input-field"
                placeholder="Digite a razão social"
                required
              />
            </div>
            <div className="form-group">
            <label htmlFor="cnpj">CNPJ/CPF</label>
            <input
            type="text"
            id="cnpj"
            name="cnpj"
            value={cnpj}
            onChange={handleCnpjChange}
            className="input-field"
            placeholder="Digite o CNPJ/CPF"
            required
          />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Telefone <span className="input-error-message">{!phone && '*'}</span></label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={handlePhoneChange}
                className="input-field"
                placeholder="(00) 00000-0000"
                required
              />
            </div>

            <p></p>

            <div className="form-group">
            <h3>DADOS DA OBRA</h3>

              <label htmlFor="projectName">Nome da obra {!companyName && <span className="input-error-message">*</span>}</label>
              <input
                type="text"
                id="projectName"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                className="input-field"
                placeholder="Digite o nome da obra"
                required 
              />
            </div>
            <div className="form-date-container">
              <div className="form-group-date">
                <label htmlFor="startDate">Data de início</label>
                <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/aaaa"
                  className="input-field"
                />
              </div>
              <div className="form-group-dateend">
                <label htmlFor="endDate">Previsão de término</label>
                <DatePicker
                  id="endDate"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/aaaa"
                  className="input-field"
                />
              </div>
            </div>
            <div className="form-group-status">
              <label htmlFor="status">Status da obra</label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="input-field"
                required 
              >
                <option value="">Selecione uma opção</option>
                <option value="Em andamento">Em andamento</option>
                <option value="Pausado">Pausado</option>
                <option value="Finalizado">Finalizado</option>     
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="cep">CEP</label>
              <input
                type="text"
                id="cep"
                value={cep}
                onChange={(e) => handleInputChange(e, setCep)}
                className="input-field"
                placeholder="00000-000"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Endereço {!companyName && <span className="input-error-message">*</span>}</label>
              <input
                type="text"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="input-field"
                placeholder="Digite o endereço"
              />
            </div>
            <div className="form-group">
              <label htmlFor="number">Número</label>
              <input
                type="text"
                id="number"
                value={number}
                onChange={(e) => handleInputChange(e, setNumber)}
                className="input-field"
                placeholder="Digite o número do local"
              />
            </div>
            <div className="form-group">
              <label htmlFor="complement">Complemento (opcional)</label>
              <input
                type="text"
                id="complement"
                value={complement}
                onChange={(e) => setComplement(e.target.value)}
                className="input-field"
                placeholder="Digite o complemento do local"
              />
            </div>
            <div className="form-group">
              <label htmlFor="neighborhood">Bairro</label>
              <input
                type="text"
                id="neighborhood"
                value={neighborhood}
                onChange={(e) => setNeighborhood(e.target.value)}
                className="input-field"
                placeholder="Digite o bairro"
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">Estado</label>
              <input
                type="text"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="input-field"
                placeholder="Digite o estado"
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">Cidade</label>
              <input
                type="text"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="input-field"
                placeholder="Digite a cidade"
              />
            </div>
            <div className="form-group-escopo">
              <label htmlFor="projectScope">Escopo do projeto</label>
              <textarea
                id="projectScope"
                value={projectScope}
                onChange={(e) => setProjectScope(e.target.value)}
                className="input-field"
                placeholder="Digite uma descrição para o lançamento"
              ></textarea>
            </div>
            <div className="form-group-button">

              <Link to="/projects" className="btn-submit" type="button" onClick={handleAddProject}>
            Cadastrar obra
          </Link>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}

export default NewProjectPage;
