import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyACrrEd2eorOeKsWKdSewKatBFTtJgfxlQ",
  authDomain: "simpllearqdash.firebaseapp.com",
  projectId: "simpllearqdash",
  storageBucket: "simpllearqdash.appspot.com",
  messagingSenderId: "313886906704",
  appId: "1:313886906704:web:60e4361496abf04b133455"
};

// Inicialize o Firebase
firebase.initializeApp(firebaseConfig);

// Exporte o Firestore, o módulo de autenticação e o módulo de armazenamento
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();


// Função para obter documentos do usuário
export const getUserDocuments = async (userId) => {
  try {
    const documentsRef = firestore.collection('user_documents').where('userId', '==', userId); // Referência à coleção 'user_documents' filtrada pelo userId
    const snapshot = await documentsRef.get(); // Obter os documentos
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Mapeie os documentos para obter os dados dos documentos do usuário
    return documents; // Retorna os documentos do usuário
  } catch (error) {
    console.error('Erro ao buscar documentos do usuário:', error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email); // Envia e-mail de redefinição de senha para o usuário
  } catch (error) {
    throw error;
  }
};

// Função para adicionar um novo ambiente à coleção de ambientes, vinculando-o à obra atual
// Função para adicionar um novo ambiente à coleção de ambientes, vinculando-o à obra atual
export const addEnvironment = async (projectId, environmentName) => {
  try {
    // Adicione o ambiente ao Firestore na coleção 'environments'
    const environmentRef = await firestore.collection('environments').add({
      projectId: projectId,
      type: environmentName,
    });
    return environmentRef.id; // Retorna o ID do ambiente adicionado
  } catch (error) {
    throw error;
  }
};

// Função para adicionar um novo material ao banco de dados e vinculá-lo à obra atual
export const addMaterial = async (projectId, environmentId, newMaterial) => {
  try {
    // Adicione o novo material ao Firestore na coleção 'materials'
    await firestore.collection('materials').add({
      projectId: projectId,
      environmentId: environmentId,
      material: newMaterial,
    });
    console.log('Novo material adicionado com sucesso!');
  } catch (error) {
    throw error;
  }
};


export const adicionarNovoAmbienteEMaterial = async (projectId, environmentName, newMaterial) => {
  try {
    // Adicionar o ambiente ao Firestore
    const ambienteRef = await firestore.collection('environments').add({
      projectId: projectId,
      name: environmentName,
    });

    // Obter o ID do ambiente recém-adicionado
    const environmentId = ambienteRef.id;

    // Adicionar o novo material vinculado ao ambiente ao Firestore
    await firestore.collection('materials').add({
      projectId: projectId,
      environmentId: environmentId,
      material: newMaterial,
    });

    console.log('Novo ambiente e material adicionados com sucesso!');
  } catch (error) {
    throw error;
  }
};
// Função para obter ambientes de uma obra específica
export const getEnvironmentsByProjectId = async (projectId) => {
  try {
    const environmentsRef = firestore.collection('environments').where('projectId', '==', projectId);
    const snapshot = await environmentsRef.get();
    const environments = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return environments;
  } catch (error) {
    console.error('Erro ao buscar ambientes da obra:', error);
    throw error;
  }
};

export const getProjectById = async (projectId) => {
  const projectRef = firestore.collection('projects').doc(projectId);
  const projectSnapshot = await projectRef.get();
  if (projectSnapshot.exists) {
    return projectSnapshot.data();
  } else {
    throw new Error('Projeto não encontrado');
  }
};


// Função para atualizar um projeto
export const updateProject = async (projectId, newData) => {
  const projectRef = firestore.collection('projects').doc(projectId);
  await projectRef.update(newData);
};

// Função de registro de usuário
export const registerUser = async (email, password, officeName) => {
  try {
    // Cria o usuário no Firebase Authentication
    await auth.createUserWithEmailAndPassword(email, password);
    
    // Obtém o ID do usuário recém-criado
    const userId = auth.currentUser.uid;

    // Salva o nome do escritório no Firestore
    await firestore.collection('users').doc(userId).set({
      email: email,
      officeName: officeName,
    });

    return userId;
  } catch (error) {
    throw error;
  }
};

// Função para fazer login com o Google
export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await auth.signInWithPopup(provider);
  } catch (error) {
    throw new Error('Erro ao fazer login com Google: ' + error.message);
  }
};

// Função de login de usuário
export const loginUser = async (email, password) => {
  try {
    const userCredential = await auth.signInWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

// Função de registro de novo projeto
export const addProject = async (projectData) => {
  try {
    // Adicione os dados do projeto ao Firestore
    const projectRef = await firestore.collection('projects').add(projectData);
    return projectRef.id; // Retorna o ID do projeto adicionado
  } catch (error) {
    throw error;
  }
};

export const getUserProjects = async (userId) => {
  try {
    const projectsRef = firestore.collection('projects'); // Referência à coleção 'projects'
    const snapshot = await projectsRef.where('userId', '==', userId).get(); // Consulta para buscar projetos do usuário
    const projects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Mapeie os documentos para obter os dados dos projetos
    return projects; // Retorna os projetos do usuário
  } catch (error) {
    console.error('Erro ao buscar projetos do usuário:', error);
    throw error; // Lança o erro para tratamento externo
  }
};

export const getProjects = async () => {
    try {
      // Acessa a coleção de projetos no Firestore
      const projectsRef = firestore.collection('projects');
      // Obtém os documentos da coleção
      const snapshot = await projectsRef.get();
      // Mapeia os documentos para extrair os dados dos projetos
      const projectsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      return projectsData;
    } catch (error) {
      throw error;
    }
  };

  export const updateProjectStatus = async (projectId, status) => {
    try {
      // Atualiza o documento do projeto com o novo status
      await firestore.collection('projects').doc(projectId).update({
        status: status
      });
      console.log('Status do projeto atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar status do projeto:', error);
      throw error;
    }
  };

  export const deletePdf = async (documentId) => {
    try {
      await firestore.collection('project_documents').doc(documentId).delete();
      console.log('PDF excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir o PDF:', error);
      throw error;
    }
  };
  
  
  // Função para salvar a URL do arquivo no Firestore
  export const saveFileUrlToFirestore = async (projectId, pdfFile, pdfFileName) => {
    try {
      // Referência ao local onde o arquivo PDF será armazenado no Firebase Storage
      const storageRef = storage.ref(`project-pdfs/${projectId}/${pdfFileName}`);
  
      // Faz o upload do arquivo PDF para o armazenamento do Firebase
      const snapshot = await storageRef.put(pdfFile);
  
      // Obtém a URL do arquivo PDF no armazenamento do Firebase
      const url = await snapshot.ref.getDownloadURL();
  
      // Salva a URL do arquivo PDF no Firestore
      await firestore.collection('project_documents').add({
        projectId: projectId,
        fileName: pdfFileName,
        url: url,
      });
  
      console.log('PDF enviado com sucesso para o projeto!');
    } catch (error) {
      console.error('Erro ao enviar PDF para o projeto:', error);
      throw error;
    }
  };
  

  export const saveImageUrlToFirestore = async (projectId, imageFile, imageName) => {
    try {
      // Referência ao local onde a imagem será armazenada no Firebase Storage
      const storageRef = storage.ref(`project-images/${projectId}/${imageName}`);
  
      // Faz o upload da imagem para o armazenamento do Firebase
      const snapshot = await storageRef.put(imageFile);
  
      // Obtém a URL da imagem no armazenamento do Firebase
      const imageUrl = await snapshot.ref.getDownloadURL();
  
      // Salva a URL da imagem no Firestore
      await firestore.collection('project_images').add({
        projectId: projectId,
        imageName: imageName,
        imageUrl: imageUrl,
      });
  
      console.log('Imagem enviada com sucesso para o projeto!');
    } catch (error) {
      console.error('Erro ao enviar imagem para o projeto:', error);
      throw error;
    }
  };

// Função para obter documentos de um projeto específico
export const getProjectDocuments = async (projectId) => {
  try {
    const documentsRef = firestore.collection('project_documents').where('projectId', '==', projectId);
    const snapshot = await documentsRef.get();
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return documents;
  } catch (error) {
    console.error('Erro ao buscar documentos do projeto:', error);
    throw error;
  }
};

  
  export const deleteProject = async (projectId) => {
    try {
      // Acessa a coleção 'projects' e exclui o documento com o ID fornecido
      await firestore.collection('projects').doc(projectId).delete();
      console.log('Projeto excluído com sucesso!');
    } catch (error) {
      throw new Error('Erro ao excluir projeto:', error);
    }
  };

export default firebase;