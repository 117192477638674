import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import NewProjectPage from './components/NewProjectPage';
import ProjectsPage from './components/ProjectsPage';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import Dashboard from './components/Dashboard';
import TrelloBoard from './components/TrelloBoard';
import MyComponent from './components/MyComponent';
import InfDetails from './components/InfDetails';
import Navbar from './components/Navbar';
import ForgotPasswordPage from './components/ForgotPasswordPage';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        {/* Renderiza o Navbar apenas se não estiver na rota de login ou registro */}
        <Navbar />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/new-project" element={<NewProjectPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:projectId" element={<ProjectDetailsPage />} />
          <Route path="/trello-board" element={<TrelloBoard />} />
          <Route path="/my-component" element={<MyComponent />} />
          <Route path="/inf-details/:projectId" element={<InfDetails />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} /> {/* Usando 'element' ao invés de 'component' */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
