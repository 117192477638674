import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { firestore, auth } from '../firebase';
import { getCurrentUserId } from '../auth';
import { FaSignOutAlt } from 'react-icons/fa';
import '../styles/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getUserProjects, deleteProject, updateProjectStatus, saveFileUrlToFirestore, getProjectDocuments } from '../firebase';
import { deletePdf } from '../firebase';

function Dashboard() {
  const [projects, setProjects] = useState(() => {
    // Tente obter os projetos salvos do localStorage ou use um array vazio como padrão
    const savedProjects = localStorage.getItem('projects');
    return savedProjects ? JSON.parse(savedProjects) : [];
  });
  
  const userId = getCurrentUserId();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileName, setPdfFileName] = useState('');
  const [projectDocuments, setProjectDocuments] = useState([]);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [isOfficeNameLoaded, setIsOfficeNameLoaded] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsRef = firestore.collection('projects');
        const snapshot = await projectsRef.where('userId', '==', userId).get();
        const projectsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Atualiza cada projeto com o status correspondente
        for (let i = 0; i < projectsList.length; i++) {
          const projectId = projectsList[i].id;
          const projectData = projectsList[i];

          // Consulta o status do projeto no Firestore
          const projectSnapshot = await firestore.collection('projects').doc(projectId).get();
          const projectStatus = projectSnapshot.data().status;

          // Atualiza o projeto com o status
          projectData.status = projectStatus;
          projectsList[i] = projectData;
        }

        setProjects(projectsList);
        // Salva os projetos atualizados no localStorage
        localStorage.setItem('projects', JSON.stringify(projectsList));
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
      }
    };

    const fetchOfficeName = async () => {
      try {
        const userDoc = await firestore.collection('users').doc(userId).get();
        const userData = userDoc.data();
        setOfficeName(userData.officeName);
      } catch (error) {
        console.error('Erro ao buscar nome do escritório:', error);
      }
    };

    fetchProjects();
    fetchOfficeName();
  }, [userId]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const openProjectDetailsModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  const formatDate = (timestamp) => {
    // Verifica se o timestamp é nulo ou indefinido
    if (!timestamp) {
      return 'Data desconhecida';
    }
  
    // Verifica se o timestamp é um objeto com o método toDate
    if (typeof timestamp.toDate !== 'function') {
      return 'Formato de data inválido';
    }
  
    // Converta o timestamp para um objeto de data JavaScript e formate-o como uma string
    const dateObject = timestamp.toDate();
    return dateObject.toLocaleDateString();
  };


  
  const confirmDeletePdf = async (documentId) => {
    const confirmDelete = window.confirm('Tem certeza de que deseja excluir este PDF?');
    if (confirmDelete) {
      try {
        await deletePdf(documentId);
        // Atualizar a lista de documentos após a exclusão
        const updatedDocuments = projectDocuments.filter(document => document.id !== documentId);
        setProjectDocuments(updatedDocuments);
      } catch (error) {
        console.error('Erro ao excluir o PDF:', error);
      }
    }
  };
  
  const handlePdfUpload = async () => {
    if (pdfFile && selectedProject && pdfFileName) {
      try {
        await saveFileUrlToFirestore(selectedProject.id, pdfFile, pdfFileName);
        setIsPdfModalOpen(false);
        setPdfFile(null);
        setPdfFileName('');
  
        // Após o envio bem-sucedido do PDF, atualize a lista de documentos do projeto
        const documents = await getProjectDocuments(selectedProject.id);
        setProjectDocuments(documents); // Atualize o estado projectDocuments
      } catch (error) {
        console.error('Erro ao enviar PDF para o projeto:', error);
      }
    } else {
      console.error('Selecione um arquivo PDF, um projeto e dê um nome ao arquivo antes de enviar.');
    }
  };

  const filteredProjects = projects.filter(project => {
    return project.projectName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleLogout = () => {
    console.log('Usuário deslogado!');
  };

   // Função para retornar a classe CSS correspondente ao status do projeto
   const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'Em andamento':
        return 'bg-blue-50 text-blue-700';
      case 'Pausado':
        return 'bg-yellow-50 text-yellow-800';
      case 'Finalizado':
        return 'bg-green-50 text-green-700';
      default:
        return 'bg-gray-50 text-gray-600';
    }
  };

  const handleOpenPdfModal = async (project) => {
    try {
      setSelectedProject(project);
      setModalMode('pdf'); // Definir o modo como 'pdf' ao abrir o modal de envio de PDF
      // Busque os documentos do projeto selecionado
      const documents = await getProjectDocuments(project.id);
      setProjectDocuments(documents);
    } catch (error) {
      console.error('Erro ao buscar documentos do projeto:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
    
            <header className="bg-white shadow">
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                  <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                </div>
              </header>

  <main>
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Projetos</h3>
        <div className="mt-5">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Buscar projeto por nome"
            className="border border-gray-300 rounded-md p-2 w-full"
          />
        </div>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {filteredProjects.map(project => (
            <div key={project.id} className="bg-white overflow-hidden shadow rounded-lg">
              <div>
              <h3 className="project-name-dash">{project.projectName}</h3>
                  <p className="project-address-dash">{project.address}</p> {/* Exibe o endereço */}

                {/* Utilize a função getStatusBadgeClass para determinar a classe CSS do status */}
                <div className='status'>{['Em andamento', 'Pausado', 'Finalizado'].includes(project.status) && (<span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${getStatusBadgeClass(project.status)}`}>
                      {project.status}
                    </span>
                  )}
                </div>

              </div>
              {/* <button onClick={() => handleOpenPdfModal(project)} className="btn-open-pdf-modal">Enviar PDF</button> */}
                  <button onClick={() => openProjectDetailsModal(project)} className="details-dash">Detalhes</button>
                  



                  {selectedProject && (
                    <div className="modal-overlay">
                      <div className="modal">
                        <button onClick={closeModal} className="modal-close">&times;</button>
                        <div className="modal-content">
                          <p><strong>Projeto:</strong> {selectedProject.projectName}</p>
                          <p><strong>Nome do Cliente:</strong> {selectedProject.companyName}</p>
                          <p><strong>CNPJ/CPF:</strong> {selectedProject.cnpj}</p>
                          <p><strong>Telefone:</strong> {selectedProject.phone}</p>
                          <p><strong>Data de início:</strong> {formatDate(selectedProject.startDate)}</p>
                          <p><strong>Data de término:</strong> {formatDate(selectedProject.endDate)}</p>
                          <p><strong>Status:</strong> {selectedProject.status}</p>
                          <p><strong>CEP:</strong> {selectedProject.cep}</p>
                          <p><strong>Endereço:</strong> {selectedProject.address}, {selectedProject.number}</p>
                          <p><strong>Bairro:</strong> {selectedProject.neighborhood}</p>
                          <p><strong>Cidade:</strong> {selectedProject.city}</p>
                          <p><strong>Escopo do projeto:</strong> {selectedProject.projectScope}</p>
                        </div>
                      </div>
                    </div>
                  )}


                    {modalMode === 'pdf' && (
                      <div className="modal-overlay">
                        <div className="modal">
                          <button onClick={() => setModalMode(null)} className="modal-close">&times;</button>
                          <div className="modal-content">
                            <h2><strong>Enviar PDF para : </strong>{selectedProject && selectedProject.projectName}</h2>
                            <input type="file" accept=".pdf" onChange={(e) => setPdfFile(e.target.files[0])} />
                            {/* Adicionando campo para dar nome ao PDF */}
                            <input type="text" placeholder="Nome do PDF" value={pdfFileName} onChange={(e) => setPdfFileName(e.target.value)} />
                            <button onClick={handlePdfUpload}>Enviar</button>

                            {/* Lista de PDFs enviados */}
                            <div>
                              <h3>PDFs Enviados:</h3>
                              <ul>
                                {projectDocuments.map(document => (
                                  <li key={document.id}>
                                    <a href={document.url} target="_blank" rel="noopener noreferrer">{document.fileName}</a>
                                    <button onClick={() => confirmDeletePdf(document.id)} className='delet-pdf'>Excluir</button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

            </div>
          ))}
        </div>
      </div>
    </div>
  </main>
</div>

);
}

export default Dashboard;