import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectById, updateProject } from '../firebase';
import '../styles/InfDetails.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function InfDetails() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [editedProject, setEditedProject] = useState(null);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectData = await getProjectById(projectId);
        setProject(projectData);
        setEditedProject({
          ...projectData,
          startDate: projectData.startDate.toDate(), // Convertendo Firestore Timestamp para Date
          endDate: projectData.endDate.toDate(), // Convertendo Firestore Timestamp para Date
        });
        setStatus(projectData.status);
      } catch (error) {
        console.error('Erro ao buscar projeto:', error);
      }
    };

    fetchProject();
  }, [projectId]);

  const handleEditProject = async () => {
    try {
      await updateProject(projectId, {
        ...editedProject,
        startDate: new Date(editedProject.startDate), // Convertendo Date de volta para Timestamp
        endDate: new Date(editedProject.endDate), // Convertendo Date de volta para Timestamp
        status: status,
      });
      toast.success('Projeto atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar projeto:', error);
      toast.error('Erro ao atualizar projeto.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Verifica se é CPF ou CNPJ e aplica a formatação adequada
    let formattedValue = value;
    if (name === 'cnpj' || name === 'cpf') {
      formattedValue = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      if (formattedValue.length === 11) {
        formattedValue = formattedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // Formato para CPF
      } else if (formattedValue.length === 14) {
        formattedValue = formattedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'); // Formato para CNPJ
      }
    }

    // Atualize o estado de editedProject com os novos valores dos campos editáveis
    setEditedProject(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleDateChange = (name, date) => {
    setEditedProject(prevState => ({
      ...prevState,
      [name]: date
    }));
  };

  if (!project) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="inf-details-container">
      <h3 className='project-details'>Detalhes do Projeto</h3>
      <div className="details-container">
        <p><strong>Nome do Projeto:</strong> <input type="text" name="projectName" value={editedProject.projectName} onChange={handleInputChange} /></p>
        <p><strong>Nome do Cliente:</strong> <input type="text" name="companyName" value={editedProject.companyName} onChange={handleInputChange} /></p>
        <p><strong>CNPJ/CPF:</strong> <input type="text" name="cnpj" value={editedProject.cnpj} onChange={handleInputChange} /></p>
        <p><strong>Telefone:</strong> <input type="text" name="phone" value={editedProject.phone} onChange={handleInputChange} /></p>
        <p><strong>Data de início:</strong> <DatePicker name="startDate" selected={editedProject.startDate} onChange={(date) => handleDateChange('startDate', date)} dateFormat="dd/MM/yyyy" /></p>
        <p><strong>Data de término:</strong> <DatePicker name="endDate" selected={editedProject.endDate} onChange={(date) => handleDateChange('endDate', date)} dateFormat="dd/MM/yyyy" /></p>
        <div className="form-group-status">
          <label htmlFor="status">Status da obra</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="input-field"
            required
          >
            <option value="">Selecione uma opção</option>
            <option value="Em andamento">Em andamento</option>
            <option value="Pausado">Pausado</option>
            <option value="Finalizado">Finalizado</option>
          </select>
        </div>
        <p><strong>CEP:</strong> <input type="text" name="cep" value={editedProject.cep} onChange={handleInputChange} /></p>
        <p><strong>Endereço:</strong> <input type="text" name="address" value={editedProject.address} onChange={handleInputChange} />, <input type="text" name="number" value={editedProject.number} onChange={handleInputChange} /></p>
        <p><strong>Bairro:</strong> <input type="text" name="neighborhood" value={editedProject.neighborhood} onChange={handleInputChange} /></p>
        <p><strong>Cidade:</strong> <input type="text" name="city" value={editedProject.city} onChange={handleInputChange} /></p>
        <p><strong>Escopo do projeto:</strong> <input type="text" name="projectScope" value={editedProject.projectScope} onChange={handleInputChange} /></p>
      </div>
      {/* Botão para salvar alterações */}
      <Link onClick={handleEditProject} to="/projects" className="save-button">Salvar Alterações</Link>
      {/* <Link to="/projects" className="backp-button">Voltar para Projetos</Link> */}

      {/* Componente ToastContainer para exibir notificações */}
      <ToastContainer />
    </div>
  );
}

export default InfDetails;
