import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import jsPDF from 'jspdf';
import '../styles/ProjectDetailsPage.css';
import 'jspdf-autotable';
import { getStorage } from 'firebase/storage';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons'; // 
import 'firebase/storage';
import ReactPaginate from 'react-paginate';
import { getCurrentUserId } from '../auth';

function ProjectDetailsPage() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [itemName, setItemName] = useState('');
  const [itemType, setItemType] = useState('');
  const [itemArea, setItemArea] = useState('');
  const [projectItems, setProjectItems] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [materials, setMaterials] = useState([]);
  const [types, setTypes] = useState([]);
  const [newType, setNewType] = useState('');
  const [itemBrand, setItemBrand] = useState('');
  const [itemModel, setItemModel] = useState('');
  const [itemColor, setItemColor] = useState('');
  const inputFileRef = useRef(null);
  const [selectedAmbiente, setSelectedAmbiente] = useState('');
  const [itemQuantity, setItemQuantity] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4; // Defina o número de itens por página aqui
  const [fakeProjectItems, setFakeProjectItems] = useState([]);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projectItems.slice(indexOfFirstItem, indexOfLastItem);
  const [officeName, setOfficeName] = useState('');
  const [projectTypes, setProjectTypes] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [isAddingNewType, setIsAddingNewType] = useState(false); 
  const [selectedMaterial, setSelectedMaterial] = useState('');


  
const [itemMaterial, setItemMaterial] = useState('');
const [isAddingNewMaterial, setIsAddingNewMaterial] = useState(false);
const [newMaterial, setNewMaterial] = useState('');
const [materialList, setMaterialList] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectRef = firestore.collection('projects').doc(projectId);
        const doc = await projectRef.get();
        if (doc.exists) {
          setProject({ id: doc.id, ...doc.data() });
        } else {
          console.log('Projeto não encontrado');
        }
      } catch (error) {
        console.error('Erro ao buscar projeto:', error);
      }
    };

    fetchProject();
  }, [projectId]);


  useEffect(() => {
    const fetchItemTypes = async () => {
      try {
        const typesRef = firestore.collection('projects').doc(projectId).collection('types');
        const snapshot = await typesRef.get();
        const typesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItemTypes(typesList);
      } catch (error) {
        console.error('Erro ao buscar tipos de itens:', error);
      }
    };
  
    fetchItemTypes();
  }, []);

  useEffect(() => {
    const fetchProjectTypes = async () => {
      try {
        const typesRef = firestore.collection('projects').doc(projectId).collection('types');
        const snapshot = await typesRef.get();
        const typesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProjectTypes(typesList);
      } catch (error) {
        console.error('Erro ao buscar tipos de itens do projeto:', error);
      }
    };
    
    if (projectId) {
      fetchProjectTypes();
    }
  }, [projectId]);

  {projectItems.map((item, index) => (
    <tr key={index}>
      <td>{item.material}</td>
      <td>{projectTypes.find(type => type.id === item.type)?.name || 'Tipo não encontrado'}</td>
    </tr>
  ))}
  


  const getProjectTypeById = async (projectId, typeId) => {
    try {
      const typesRef = firestore.collection('projects').doc(projectId).collection('types');
      const doc = await typesRef.doc(typeId).get();
      if (doc.exists) {
        return doc.data(); // Retorna o documento inteiro do tipo se o documento existir
      } else {
        return null;
      }
    } catch (error) {
      console.error('Erro ao buscar tipo de item:', error);
      return null;
    }
  };




useEffect(() => {
  const fetchProjectItems = async () => {
    try {
      const itemsRef = firestore.collection('projects').doc(projectId).collection('items');
      let query = itemsRef;
      if (selectedMaterial !== '') {
        query = query.where('material', '==', selectedMaterial);
      }
      if (selectedAmbiente !== '') {
        query = query.where('type', '==', selectedAmbiente); // Alteração aqui para usar 'type' em vez de 'ambiente'
      }
      const snapshot = await query.get();
      const itemsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjectItems(itemsList);
    } catch (error) {
      console.error('Erro ao buscar itens do projeto:', error);
    }
  };

  fetchProjectItems();
}, [projectId, selectedMaterial, selectedAmbiente]);


{projectItems.map(async (item, index) => (
  <tr key={index}>
    {/* Restante do código permanece o mesmo */}
    <td>{(await getProjectTypeById(projectId, item.type))?.name || 'Tipo não encontrado'}</td>
  </tr>
))}

useEffect(() => {
  const fetchMaterials = async () => {
    try {
      const materialsRef = firestore.collection('projects').doc(projectId).collection('materials');
      const snapshot = await materialsRef.get();
      const materialsList = snapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })); // Pegar apenas o nome do material
      setMaterialList(materialsList);
    } catch (error) {
      console.error('Erro ao buscar materiais:', error);
    }
  };

  fetchMaterials();
}, [projectId]); // Execute novamente quando o projectId mudar

useEffect(() => {
  const fetchTypes = async () => {
    try {
      const typesRef = firestore.collection('types');
      const snapshot = await typesRef.get();
      const typesList = snapshot.docs.map(doc => doc.data().name);
      setTypes(typesList);
    } catch (error) {
      console.error('Erro ao buscar tipos:', error);
    }
  };

  fetchTypes();
}, []);
  

  const handleSave = async () => {
    try {
      await firestore.collection('projects').doc(projectId).update(editedProject);
      setEditing(false);
      setProject(editedProject);
      alert('Projeto atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar projeto:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProject(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const fetchOfficeName = async () => {
    try {
      const userId = getCurrentUserId();
      const userDoc = await firestore.collection('users').doc(userId).get();
      const userData = userDoc.data();
      setOfficeName(userData.officeName);
    } catch (error) {
      console.error('Erro ao buscar nome do escritório:', error);
    }
  };

  useEffect(() => {
    fetchOfficeName(); // Chame a função no useEffect
  }, []);

  const handleCameraButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const addItemToProject = async () => {
  try {
    let imageUrl = ''; // URL da imagem, inicializado como uma string vazia

    if (imageFile) {
      // Se uma imagem foi selecionada, faça o upload para o Firebase Storage
      const storageRef = ref(getStorage(), `project_images/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      imageUrl = await getDownloadURL(storageRef);
    }

    // Adicionar o item ao Firestore, incluindo a URL da imagem (ou uma string vazia se não houver imagem)
    const newItemData = {
      name: itemName,
      brand: itemBrand,
      model: itemModel,
      color: itemColor,
      material: itemMaterial === 'outro' ? newMaterial : itemMaterial,
      type: itemType === 'outro' ? newType : itemType,
      area: itemArea,
      quantity: itemQuantity,
      imageUrl: imageUrl, // URL da imagem (ou string vazia)
    };

    const newItemRef = await firestore.collection('projects').doc(projectId).collection('items').add(newItemData);

    const newItem = {
      id: newItemRef.id,
      ...newItemData,
    };

    setProjectItems((prevItems) => [...prevItems, newItem]);

    // Limpar os campos do formulário
    setImageFile(null);
    setItemName('');
    setItemBrand('');
    setItemModel('');
    setItemColor('');
    setItemMaterial('');
    setItemType('');
    setItemArea('');
    setItemQuantity('');

    alert('Item adicionado ao projeto com sucesso!');
  } catch (error) {
    console.error('Erro ao adicionar item ao projeto:', error);
  }
};

const handlePageChange = ({ selected }) => {
  setCurrentPage(selected);
};
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const getProjectNameFromFirebase = async () => {
    try {
      return "Memorial Descritivo"; // Exemplo de nome
    } catch (error) {
      console.error('Erro ao buscar nome do projeto:', error);
      return "Projeto Sem Nome"; // Valor padrão em caso de erro
    }
  };

  const handleSaveEdit = async (item) => {
    try {
      let updatedImageUrl = editedItem.imageUrl; // Mantenha a URL da imagem existente por padrão
  
      if (imageFile) {
        // Se uma nova imagem foi selecionada, faça o upload para o Firebase Storage
        const storageRef = ref(getStorage(), `project_images/${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        updatedImageUrl = await getDownloadURL(storageRef);
      }
  
      // Atualize os dados do item no Firestore
      await firestore.collection('projects').doc(projectId).collection('items').doc(item.id).update({
        ...editedItem,
        imageUrl: updatedImageUrl, // Use a URL atualizada da imagem
      });
  
      setEditingItemId(null); // Limpar o estado de edição
      alert('Item atualizado com sucesso!');
      window.location.reload(); // Recarregar a página após a atualização
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
    }
  };

  const handleGeneratePDF = async () => {
    const projectName = await getProjectNameFromFirebase();
    const doc = new jsPDF();
    let y = 20;
  
    doc.setFontSize(18);
    doc.text(projectName, 10, y);
    y += 10;
  
    const imgWidth = 20;
    const imgHeight = 20;
    const imgMargin = 3;
    const cellPadding = 4;
  
    const columns = ['Foto', 'Descrição', 'Material', 'Ambiente', 'M²', 'Quantidade'];
    const rows = [];
  
    const promises = [];
  
    // Filtrando os itens com base nos materiais e ambientes selecionados
    const filteredItems = projectItems.filter(item => {
      return (
        (selectedMaterial === '' || item.material === selectedMaterial) &&
        (selectedAmbiente === '' || item.type === selectedAmbiente)
      );
    });
  
    // Função para pré-carregar uma imagem de forma assíncrona
    const loadImage = async (imageUrl) => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const imageUrlObject = URL.createObjectURL(blob);
        return new Promise(resolve => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.src = imageUrlObject;
        });
      } catch (error) {
        console.error('Erro ao carregar a imagem:', error);
        return null;
      }
    };
  
    // Pre-carregar imagens assincronamente
    for (const item of filteredItems) {
      if (item.imageUrl) {
        const promise = loadImage(item.imageUrl);
        promises.push(promise);
      }
    }
  
    try {
      // Aguardar o carregamento de todas as imagens
      const images = await Promise.all(promises);
  
      // Adicionar dados à tabela do PDF
      filteredItems.forEach((item, index) => {
        const rowData = [
          images[index], // Imagem pré-carregada
          `Nome: ${item.name}\nMarca: ${item.brand}\nModelo: ${item.model}\nCor: ${item.color}`,
          item.material === 'outro' ? item.newMaterial : item.material,
          item.type === 'outro' ? item.newType : getProjectTypeNameById(item.type),
          item.area,
          item.quantity,
        ];
        rows.push(rowData);
      });
  
      // Adicionar tabela ao PDF
      doc.autoTable({
        startY: y,
        head: [columns],
        body: rows,
        theme: 'grid',
        headStyles: {
          fillColor: [128], // Define a cor do cabeçalho como vermelho (RGB)
        },
        styles: {
          halign: 'center',
          cellPadding: cellPadding,
          valign: 'middle',
          cellWidth: 'auto',
          columnWidth: 'wrap',
          overflow: 'linebreak',
        },
        columnStyles: {
          0: { cellWidth: imgWidth + (imgMargin * 4) },
          1: { cellWidth: 'auto', halign: 'left' },
        },
        didDrawCell: (data) => {
          if (data.column.index === 0 && data.cell.raw instanceof HTMLImageElement) {
            const img = data.cell.raw;
            const x = data.cell.x + imgMargin;
            const y = data.cell.y + imgMargin;
            doc.addImage(img, x, y, imgWidth, imgHeight);
          }
        },
      });
  
      // Salvando o PDF somente se houverem linhas a serem adicionadas
      if (rows.length > 0) {
        doc.save(`${projectName}.pdf`);
      } else {
        alert('Não há dados para gerar o PDF com os critérios selecionados.');
      }
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
      alert('Erro ao gerar o PDF. Verifique o console para mais detalhes.');
    }
  };
  

const getProjectTypeNameById = (typeId) => {
  const type = projectTypes.find((type) => type.id === typeId);
  return type ? type.name : "Tipo não encontrado";
};


const [editingItemId, setEditingItemId] = useState(null); // ID do item em edição
const [editedItem, setEditedItem] = useState({
  name: '',
  brand: '',
  model: '',
  color: '',
  material: '',
  type: '',
  area: '',
  quantity: '',
});

const handleEditItem = (item) => {
  setEditingItemId(item.id);
  setEditedItem({
    ...item,
    // Inicialize a imagem editada com a imagem atual do item, ou uma string vazia se não houver imagem
    imageUrl: item.imageUrl || '',
  });
};

const addNewMaterial = async () => {
  try {
    // Verifica se o novo material já existe na lista de materiais
    const existingMaterial = materialList.find(material => material.name === newMaterial);

    // Se o material já existir, exiba uma mensagem de alerta
    if (existingMaterial) {
      alert('Este material já existe.');
      return;
    }

    // Se o material não existir, adicione-o à coleção de materiais dentro do projeto atual
    const materialData = { name: newMaterial };
    const materialsRef = firestore.collection('projects').doc(projectId).collection('materials');
    const docRef = await materialsRef.add(materialData);

    const materialId = docRef.id;
    const newMaterialWithId = { id: materialId, name: newMaterial }; // Usar o nome inserido pelo usuário

    setMaterialList(prevMaterials => [...prevMaterials, newMaterialWithId]);
    setNewMaterial(''); // Limpa o campo do novo material
    alert('Novo material adicionado com sucesso!');
    setIsAddingNewMaterial(false);
    setItemMaterial('');
  } catch (error) {
    console.error('Erro ao adicionar novo material:', error);
  }
};



const addNewType = async () => {
  try {
    // Verifica se o novo tipo já existe na lista de tipos
    const existingType = projectTypes.find(type => type.name === newType);
    
    // Se o tipo já existir, exiba uma mensagem de alerta
    if (existingType) {
      alert('Este tipo já existe.');
      return;
    }
    
    // Se o tipo não existir, adicione-o ao Firestore e à lista de tipos
    const typeData = { name: newType };
    const docRef = await firestore.collection('projects').doc(projectId).collection('types').add(typeData);
    const typeId = docRef.id;
    const newTypeWithId = { id: typeId, ...typeData };
    setProjectTypes(prevTypes => [...prevTypes, newTypeWithId]);
    setNewType(''); // Limpa o campo do novo tipo
    alert('Novo tipo adicionado com sucesso!');
    setIsAddingNewType(false);
    setNewType('');
  } catch (error) {
    console.error('Erro ao adicionar novo tipo:', error);
  }
};

const handleSaveButtonClick = () => {
  addNewType();
  setIsAddingNewType(false);
};

const removeItemFromProject = async (itemId) => {
try {
  await firestore.collection('projects').doc(projectId).collection('items').doc(itemId).delete();
  alert('Item removido do projeto com sucesso!');
  // Recarregue os itens do projeto após a remoção
  const itemsRef = firestore.collection('projects').doc(projectId).collection('items');
  const snapshot = await itemsRef.get();
  const itemsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  setProjectItems(itemsList);
} catch (error) {
  console.error('Erro ao remover item do projeto:', error);
}
};

const handleAreaChange = (e) => {
const inputValue = e.target.value;
// Verificar se o valor inserido é um número
if (/^\d*\.?\d*$/.test(inputValue)) {
  setItemArea(inputValue);
}
};

return (

  <div>

          <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold text-gray-900">Memorial Descritivo</h1>
                {project && ( // Verifica se project está definido
                <p className='name-project-memorial'>Projeto: {project.projectName}</p> // Renderiza o nome do projeto
              )}
            </div>
          </header>             

    <div className="project-section">
      <div className="add-item-section">
        <div className="input-group">
          <label htmlFor="itemName">Nome:</label>
          <input
            type="text"
            id="itemName"
            name="itemName"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
        </div>
        <div className="input-group-brand">
          <label htmlFor="itemBrand">Marca:</label>
          <input
            type="text"
            id="itemBrand"
            name="itemBrand"
            value={itemBrand}
            onChange={(e) => setItemBrand(e.target.value)}
          />
        </div>

        <div className="input-group-model">
          <label htmlFor="itemModel">Modelo:</label>
          <input
            type="text"
            id="itemModel"
            name="itemModel"
            value={itemModel}
            onChange={(e) => setItemModel(e.target.value)}
          />
        </div>
        <div className="input-group-color">
          <label htmlFor="itemColor">Cor:</label>
          <input
            type="text"
            id="itemColor"
            name="itemColor"
            value={itemColor}
            onChange={(e) => setItemColor(e.target.value)}
          />
        </div>
        <div className="input-group-area">
          <label htmlFor="itemArea">Área:</label>
          <input
            type="text"
            id="itemArea"
            name="itemArea"
            value={itemArea}
            onChange={handleAreaChange}
          />
        </div>  
        <div className="input-group-area">
          <label htmlFor="itemQuantity">Quantidade:</label>
          <input
            type="text"
            id="itemQuantity"
            name="itemQuantity"
            value={itemQuantity}
            onChange={(e) => setItemQuantity(e.target.value)}
          />
        </div>
        <div className="input-group-material">
    <label htmlFor="itemMaterial" className="mr-2">
      Material:
    </label>
    <div className="relative inline-block text-left">
      {isAddingNewMaterial ? (
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Digite o novo material"
            value={newMaterial}
            onChange={(e) => setNewMaterial(e.target.value)}
            className="w-full p-2 pr-8 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-auto"
          />
          <button onClick={addNewMaterial} className="btn-save-new-material ml-2">
            Salvar
          </button>
        </div>
      ) : (
        <select
          id="itemMaterial"
          name="itemMaterial"
          value={itemMaterial}
          onChange={(e) => {
            if (e.target.value === 'outro') {
              setIsAddingNewMaterial(true);
            } else {
              setItemMaterial(e.target.value);
            }
          }}
          className="block w-full p-2 pr-8 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-auto"
        >
          <option value="">Selecione um material...</option>
          {materialList.map((material) => (
            <option key={material.id} value={material.name}> {/* Usar o nome do material como value */}
              {material.name}
            </option>
          ))}
          <option className='digite-aqui' value="outro">Digite aqui..</option>
        </select>
      )}
    </div>
  </div>

        
          <div className="input-group-ambiente">
          <label htmlFor="itemType" className="mr-2">
            Ambiente:
          </label>
          <div className="relative inline-block text-left">
            {isAddingNewType ? (
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Digite o novo tipo"
                  value={newType}
                  onChange={(e) => setNewType(e.target.value)}
                  className="w-full p-2 pr-8 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-auto"
                />
                <button onClick={addNewType} className="btn-save-new-type ml-2">
                  Salvar
                </button>
              </div>
            ) : (
              <select
                id="itemType"
                name="itemType"
                value={itemType}
                onChange={(e) => {
                  if (e.target.value === 'outro') {
                    setIsAddingNewType(true);
                  } else {
                    setItemType(e.target.value);
                  }
                }}
                className="block w-full p-2 pr-8 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm md:w-auto"
              >
                <option value="">Selecione um tipo...</option>
                {projectTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
                <option className='digite-aqui' value="outro">Digite aqui..</option>
              </select>
            )}
          </div>
        </div>


          <div className="input-group-cam camera-input">
            <label htmlFor="itemImage">Imagem:</label>
            <input
              ref={inputFileRef} // Referência para o input de arquivo
              type="file"
              id="itemImage"
              name="itemImage"
              onChange={handleImageChange}
              style={{ display: 'none' }} // Estilo para esconder o input de arquivo
            />
            <button className="purple-button" onClick={handleCameraButtonClick}>
              <FontAwesomeIcon icon={faCamera} /> {/* Ícone da câmera */}
            </button>
          </div>
          {/* <button className="auth-button-add" onClick={addItemToProject}></button> */}
          <button onClick={addItemToProject} className="auth-button-add"><span className="icon-folder"></span></button>
          {/* <button className="auth-button" onClick={handleLogin}>Login</button> */}
        </div>

        <p className="text-filter">Filtre a Tabela</p>
              <form id="filtrogeralForm" className='filtrogeral-form'>

                  <label htmlFor="materialSelect" className="filtrogeral-label">Material:</label>
                  <select
                    id="materialSelect"
                    value={selectedMaterial}
                    onChange={(e) => setSelectedMaterial(e.target.value)}
                    className="filtrogeral-select"
                  >
                    <option value="">Todos</option>
                    {materialList.map((material) => (
                      <option key={material.id} value={material.name}>
                        {material.name}
                      </option>
                    ))}
                  </select>

                <label htmlFor="ambienteSelect" className="filtrogeral-label">Ambiente:</label>
                  <select id="ambienteSelect" value={selectedAmbiente} onChange={(e) => setSelectedAmbiente(e.target.value)} className="filtrogeral-select">
                    <option value="">Todos</option>
                    {itemTypes.map((type) => (
                      <option key={type.id} value={type.id}>{type.name}</option>
                    ))}
                  </select>
              </form>
  
        <div className="items-list">
      {projectItems.length > 0 && (
        <div>
          <table>
            <thead>
              <tr>
                <th>Foto</th>
                <th>Descrição</th>
                <th>Categoria</th>
                <th>Ambiente</th>
                <th>M²</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td className="item-image-container">
                    {editingItemId === item.id ? (
                      <div>
                        {editedItem.imageUrl ? (
                          <img className="item-image" src={editedItem.imageUrl} alt={`Imagem de ${editedItem.name}`} />
                        ) : (
                          <span>Sem imagem</span>
                        )}
                        <input
                          type="file"
                          onChange={handleImageChange}
                        />
                      </div>
                    ) : (
                      item.imageUrl ? (
                        <img className="item-image" src={item.imageUrl} alt={`Imagem de ${item.name}`} />
                      ) : (
                        <span>Sem imagem</span>
                      )
                    )}
                  </td>
                  <td className="item-details">
                    {editingItemId === item.id ? (
                      <div>
                        <strong>Nome:</strong>
                        <input
                          type="text"
                          value={editedItem.name}
                          onChange={(e) => setEditedItem({ ...editedItem, name: e.target.value })}
                        />
                        <br />
                        <strong>Marca:</strong>
                        <input
                          type="text"
                          value={editedItem.brand}
                          onChange={(e) => setEditedItem({ ...editedItem, brand: e.target.value })}
                        />
                        <br />
                        <strong>Modelo:</strong>
                        <input
                          type="text"
                          value={editedItem.model}
                          onChange={(e) => setEditedItem({ ...editedItem, model: e.target.value })}
                        />
                        <br />
                        <strong>Cor:</strong>
                        <input
                          type="text"
                          value={editedItem.color}
                          onChange={(e) => setEditedItem({ ...editedItem, color: e.target.value })}
                        />
                      </div>
                    ) : (
                      <div>
                        <strong>Nome:</strong> {item.name}<br />
                        <strong>Marca:</strong> {item.brand}<br />
                        <strong>Modelo:</strong> {item.model}<br />
                        <strong>Cor:</strong> {item.color}<br />
                      </div>
                    )}
                  </td>
                  <td>
                    {editingItemId === item.id ? (
                      <select
                        value={editedItem.material}
                        onChange={(e) => setEditedItem({ ...editedItem, material: e.target.value })}
                      >
                        {materials.map((material, index) => (
                          <option key={index} value={material}>
                            {material}
                          </option>
                        ))}
                      </select>
                    ) : (
                      item.material === 'outro' ? editedItem.newMaterial : item.material
                    )}
                  </td>
                  <td>
                    {editingItemId === item.id ? (
                      <select
                        value={editedItem.type}
                        onChange={(e) => setEditedItem({ ...editedItem, type: e.target.value })}
                      >
                        {itemTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      getProjectTypeNameById(item.type)
                    )}
                  </td>
                  <td>
                    {editingItemId === item.id ? (
                      <input
                        type="text"
                        value={editedItem.area}
                        onChange={(e) => setEditedItem({ ...editedItem, area: e.target.value })}
                      />
                    ) : (
                      item.area
                    )}
                  </td>
                  <td>
                    {editingItemId === item.id ? (
                      <input
                        type="text"
                        value={editedItem.quantity}
                        onChange={(e) => setEditedItem({ ...editedItem, quantity: e.target.value })}
                      />
                    ) : (
                      item.quantity
                    )}
                  </td>
                  <div>
                    {editingItemId === item.id ? (
                      <button onClick={() => handleSaveEdit(item)}>Salvar</button>
                    ) : (
                      <button onClick={() => handleEditItem(item)}>Editar</button>
                    )}
                    <button onClick={() => removeItemFromProject(item.id)}>Remover</button>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>


        </div>
      )}
      <div className='paginate-class'>
                  <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={Math.ceil(projectItems.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
      </div>
    </div>
        
            <button type="button" onClick={handleGeneratePDF} className="form-button">Gerar PDF</button>
      </div>
    </div>
  );
     }

export default ProjectDetailsPage;