// src/auth.js

// Suponha que você esteja usando o Firebase Authentication
import { auth } from './firebase';

// Função para obter o ID do usuário atualmente autenticado
export const getCurrentUserId = () => {
  const user = auth.currentUser;
  return user ? user.uid : null;
};
