import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { resetPassword } from '../firebase'; // Importe a função para redefinir a senha
import '../tailwindAuth.css';
import logo from '../img/nex1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { loginUser } from '../firebase'; // Verifique o caminho correto para o arquivo firebase.js

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    try {
      await loginUser(email, password);
      setNotification('Login bem-sucedido!');
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setNotification('Credencial de autenticação inválida. Por favor, verifique suas credenciais e tente novamente.');
      } else {
        setNotification('Erro ao fazer login: ' + error.message);
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      await resetPassword(email); // Função para enviar e-mail de redefinição de senha
      setNotification('Um e-mail de redefinição de senha foi enviado para o seu endereço de e-mail.');
    } catch (error) {
      setNotification('Erro ao solicitar redefinição de senha: ' + error.message);
    }
  };

  return (
    <div className="flex-shrink-0">
      <img className="nex" src={logo} alt="Your Company" />
      <div className="container">
        <div className="auth-container">
          <h2 className="auth-heading">Login</h2>
          <input
            className="auth-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="auth-input"
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="eye-icon-login"
            onClick={() => setShowPassword(!showPassword)}
          />
          <button className="auth-button" onClick={handleLogin}>
            Login
          </button>
          <Link className="auth-link" to="/register">
            Registrar
          </Link>
          {/* Adicionar o link "Esqueci a senha" */}
   <Link className="auth-link" to="/forgot-password">
        Esqueci a senha
      </Link>
        </div>
      </div>
      <p className='version-login'>Versão Beta: 0.0.1-beta</p>
      {notification && (
        <div className="fixed top-0 right-0 mt-4 mr-4">
          <div className="bg-green-500 text-white p-4 rounded-md shadow-md">
          {notification === 'Login bem-sucedido!' && <Navigate to="/dashboard" />}
           </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
