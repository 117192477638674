// ForgotPasswordPage.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Importe o Link do react-router-dom
import { resetPassword } from '../firebase';
import '../styles/forgotPasswordPage.css';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState('');

  const handleForgotPassword = async () => {
    try {
      await resetPassword(email);
      setNotification('Um e-mail de recuperação de senha foi enviado para o seu endereço de e-mail.');
    } catch (error) {
      setNotification('Erro ao solicitar recuperação de senha: ' + error.message);
    }
  };

  return (
    <div className="container">
      <div className="auth-container">
        <h2 className="auth-heading">Recuperar Senha</h2>
        <input
          type="email"
          className="auth-input"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="auth-button" onClick={handleForgotPassword}>
          Enviar E-mail de Recuperação
        </button>
        {notification && <p className="notification">{notification}</p>}
        <Link to="/" className="auth-link">Voltar para o Login</Link>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
