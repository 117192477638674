import React, { useState } from 'react';
import { registerUser } from '../firebase';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../tailwindAuthRegister.css';
import logo from '../img/nex1.png';
import { auth } from '../firebase'; // Importe o objeto 'auth' do Firebase

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [notification, setNotification] = useState('');
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleRegister = async () => {
    try {
      if (!isStrongPassword) {
        setNotification('Senha deve conter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais.');
        return;
      }
  
      if (password !== confirmPassword) {
        setNotification('As senhas não coincidem.');
        return;
      }
  
      if (!agreeTerms) {
        setNotification('Você precisa concordar com os termos para prosseguir.');
        return;
      }
  
      // Registra o usuário no Firebase Authentication
      const userCredential = await auth.createUserWithEmailAndPassword(email, password);
  
      // Envia o email de verificação para o usuário
      await userCredential.user.sendEmailVerification();
  
      setNotification('Usuário registrado com sucesso! Um email de verificação foi enviado para sua caixa de entrada.');
    } catch (error) {
      setNotification('Erro ao registrar usuário: ' + error.message);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Verifica se a senha possui pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    setIsStrongPassword(strongPasswordRegex.test(value));
  };

  return (
    <div className="flex-shrink-0">
      {/* <img className="nex" src={logo} alt="Your Company" /> */}
      <div className="auth-container">
        <h2 className="auth-heading">Registrar</h2>
        <input
          className="auth-input"
          type="text"
          placeholder="Nome do Escritório"
          value={officeName}
          onChange={(e) => setOfficeName(e.target.value)}
        />
        <input
          className="auth-input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="relative">
          <input
            className="auth-input"
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            value={password}
            onChange={handlePasswordChange}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="eye-icon"
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        <input
          className="auth-input"
          type="password"
          placeholder="Confirmar Senha"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {!isStrongPassword && (
          <div className="text-red-500 text-xs mt-1">
            {/* Senha deve conter pelo menos 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais. */}
          </div>
        )}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="mr-2"
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
          />
          <label className="text-sm text-gray-600">Concordo com os termos</label>
        </div>
        <button className="auth-button" onClick={handleRegister}>
          Registrar
        </button>
        <Link className="auth-link" to="/">
          Já tem uma conta? Faça login
        </Link>
      </div>
      <p className="version-register">Versão Beta: 0.0.1-beta</p>

      {notification && (
        <div className="fixed top-0 right-0 mt-4 mr-4">
          <div className="bg-green-500 text-white p-4 rounded-md shadow-md">
            {notification}
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterPage;
