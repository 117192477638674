// Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { firestore } from '../firebase'; // Importe seu módulo de conexão com o Firestore
import '../styles/Navbar.css';
import logo from '../img/logo.png';
import { getCurrentUserId } from '../auth';

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [officeName, setOfficeName] = useState('');

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    console.log('Usuário deslogado!');
    // Aqui você pode adicionar lógica adicional para redirecionar o usuário para a página de login, se necessário
  };

  const fetchOfficeName = async (userId) => {
    try {
      const userDoc = await firestore.collection('users').doc(userId).get();
      const userData = userDoc.data();
      if (userData) {
        const storedOfficeName = localStorage.getItem('officeName');
        const fetchedOfficeName = userData.officeName;
        
        if (!storedOfficeName || storedOfficeName !== fetchedOfficeName) {
          localStorage.setItem('officeName', fetchedOfficeName);
          setOfficeName(fetchedOfficeName);
        } else {
          setOfficeName(storedOfficeName);
        }
      }
    } catch (error) {
      console.error('Erro ao buscar o nome do escritório:', error);
    }
  };

  useEffect(() => {
    const userId = getCurrentUserId(); // Obter o ID do usuário autenticado
    if (userId) {
      fetchOfficeName(userId); // Chama fetchOfficeName com o ID do usuário
    }
  }, []); // Dependência vazia para executar apenas uma vez após a montagem do componente

  useEffect(() => {
    // Ao montar o componente, verificar se há um nome de escritório no localStorage
    const storedOfficeName = localStorage.getItem('officeName');
    if (storedOfficeName) {
      setOfficeName(storedOfficeName);
    }
  }, []); // Dependência vazia para executar apenas uma vez após a montagem do componente

  // Lista de rotas em que o Navbar não deve ser renderizado
  const hideNavbarRoutes = ['/register', '/', '/forgot-password'];

  // Verifica se a rota atual está na lista de rotas que devem ocultar o Navbar
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  if (shouldHideNavbar) {
    return null; // Não renderiza o Navbar se a rota atual estiver na lista
  }

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/dashboard" className="text-white">
              <img className="h-8 w-8" src={logo} alt="Your Company" />
            </Link>
          </div>
          <div className="block md:hidden">
            <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
              Menu
            </button>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center space-x-4">
              <Link
                to="/dashboard"
                onClick={closeMobileMenu}
                className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </Link>
              <Link
                to="/new-project"
                onClick={closeMobileMenu}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Novo Projeto
              </Link>
              <Link
                to="/projects"
                onClick={closeMobileMenu}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Projetos
              </Link>
              <Link
                to="/trello-board"
                onClick={closeMobileMenu}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Tarefas
              </Link>
              <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                <span>{officeName}</span>
                <FontAwesomeIcon icon={faUserCircle} className="avatar-icon" />
              </div>
              <Link
                to="/"
                onClick={handleLogout}
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Sair
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/dashboard"
              onClick={closeMobileMenu}
              className="text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </Link>
            <Link
              to="/new-project"
              onClick={closeMobileMenu}
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Novo Projeto
            </Link>
            <Link
              to="/projects"
              onClick={closeMobileMenu}
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Projetos
            </Link>
            <Link
              to="/trello-board"
              onClick={closeMobileMenu}
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Tarefas
            </Link>
            <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <span>{officeName}</span>
              <FontAwesomeIcon icon={faUserCircle} className="avatar-icon" />

            </div>
            <Link
              to="/"
              onClick={handleLogout}
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-sm font-medium"
            >
              Logout
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
