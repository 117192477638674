// eslint-disable-next-line react/no-unknown-property


import React, { useEffect, useState } from 'react';
import { getUserProjects, deleteProject, updateProjectStatus, saveFileUrlToFirestore, getProjectDocuments } from '../firebase';
import { getCurrentUserId } from '../auth';
import { Link } from 'react-router-dom'; // Importe o componente Link
import logo from '../img/logo.png';
import { FaSignOutAlt } from 'react-icons/fa';
import '../styles/ProjectsPage.css';
import { firestore, auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import InfDetails from './InfDetails';
import { useParams } from 'react-router-dom';
import { deletePdf } from '../firebase'; // Importe a função deletePdf

function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  const [projectDocuments, setProjectDocuments] = useState([]); // Estado para armazenar os documentos do projeto selecionado
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [officeName, setOfficeName] = useState('');
  const [selectedProject, setSelectedProject] = useState(null); // Estado para armazenar o projeto selecionado
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Estado para controlar a abertura do modal de edição
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false); // Estado para controlar a abertura do modal de envio de PDF
  const [pdfFile, setPdfFile] = useState(null); // Estado para armazenar o arquivo PDF selecionado
  const [pdfFileName, setPdfFileName] = useState(''); // Estado para armazenar o nome do arquivo PDF
  const [mode, setMode] = useState('details'); // Estado para controlar o modo do modal
  const [modalMode, setModalMode] = useState(null); // Estado para controlar o modo do modal

  useEffect(() => {
    const fetchUserProjects = async () => {
      try {
        const userId = getCurrentUserId();
        const userProjects = await getUserProjects(userId);
        setProjects(userProjects);
      } catch (error) {
        console.error('Erro ao buscar projetos:', error);
      }
    };

    fetchUserProjects();
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    console.log('Usuário deslogado!');
  };

  const openProjectDetailsModal = (project) => {
    setSelectedProject(project);
    setModalMode('details'); // Definir o modo como 'details' ao abrir os detalhes do projeto
  };


  const closeModal = () => {
    setSelectedProject(null);
    setModalMode(null); // Limpar o modo do modal ao fechar o modal de detalhes do projeto
  };


  const fetchOfficeName = async () => {
    try {
      const userId = getCurrentUserId();
      const userDoc = await firestore.collection('users').doc(userId).get();
      const userData = userDoc.data();
      setOfficeName(userData.officeName);
    } catch (error) {
      console.error('Erro ao buscar nome do escritório:', error);
    }
  };

  useEffect(() => {
    fetchOfficeName(); // Chame a função no useEffect
  }, []);

  const confirmDeleteProject = async (projectId) => {
    const confirmDelete = window.confirm('Tem certeza de que deseja excluir este projeto?');
    if (confirmDelete) {
      try {
        await deleteProject(projectId);
        const userId = getCurrentUserId();
        const userProjects = await getUserProjects(userId);
        setProjects(userProjects);
      } catch (error) {
        console.error('Erro ao excluir projeto:', error);
      }
    }
  };

  const handleStatusChange = async (projectId, status) => {
    try {
      await updateProjectStatus(projectId, status);
      const userId = getCurrentUserId();
      const userProjects = await getUserProjects(userId);
      setProjects(userProjects);
    } catch (error) {
      console.error('Erro ao alterar o status do projeto:', error);
    }
  };
  
  // Função para retornar a classe CSS com base no status
  const getStatusClass = (status) => {
    if (status === 'Em andamento') {
      return 'bg-blue-50 text-blue-700 border-blue-300';
    } else if (status === 'Pausado') {
      return 'bg-red-50 text-red-700 border-red-300';
    } else {
      return 'bg-green-50 text-green-700 border-green-300';
    }
  };

  const formatDate = (timestamp) => {
    // Verifica se o timestamp é nulo ou indefinido
    if (!timestamp) {
      return 'Data desconhecida';
    }
  
    // Verifica se o timestamp é um objeto com o método toDate
    if (typeof timestamp.toDate !== 'function') {
      return 'Formato de data inválido';
    }
  
    // Converta o timestamp para um objeto de data JavaScript e formate-o como uma string
    const dateObject = timestamp.toDate();
    return dateObject.toLocaleDateString();
  };

  const openEditModal = (projectId) => {
    // Use o componente Link para navegar para a rota de edição do projeto
    return (
      <Link to={`/inf-details/${projectId}`} className="edit-link">Editar</Link>
    );
  };
  
  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handlePdfUpload = async () => {
    if (pdfFile && selectedProject && pdfFileName) {
      try {
        await saveFileUrlToFirestore(selectedProject.id, pdfFile, pdfFileName);
        setIsPdfModalOpen(false);
        setPdfFile(null);
        setPdfFileName('');
  
        // Após o envio bem-sucedido do PDF, atualize a lista de documentos do projeto
        const documents = await getProjectDocuments(selectedProject.id);
        setProjectDocuments(documents); // Atualize o estado projectDocuments
      } catch (error) {
        console.error('Erro ao enviar PDF para o projeto:', error);
      }
    } else {
      console.error('Selecione um arquivo PDF, um projeto e dê um nome ao arquivo antes de enviar.');
    }
  };
  


  const handleOpenPdfModal = async (project) => {
    try {
      setSelectedProject(project);
      setModalMode('pdf'); // Definir o modo como 'pdf' ao abrir o modal de envio de PDF
      // Busque os documentos do projeto selecionado
      const documents = await getProjectDocuments(project.id);
      setProjectDocuments(documents);
    } catch (error) {
      console.error('Erro ao buscar documentos do projeto:', error);
    }
  };

  const confirmDeletePdf = async (documentId) => {
    const confirmDelete = window.confirm('Tem certeza de que deseja excluir este PDF?');
    if (confirmDelete) {
      try {
        await deletePdf(documentId);
        // Atualizar a lista de documentos após a exclusão
        const updatedDocuments = projectDocuments.filter(document => document.id !== documentId);
        setProjectDocuments(updatedDocuments);
      } catch (error) {
        console.error('Erro ao excluir o PDF:', error);
      }
    }
  };

  
  

  return (
    <div>
      

      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Seus Projetos</h1>
        </div>
      </header>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {projects.map(project => (
              <div key={project.id} className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="project-name">{project.projectName}</h3>
                  <p className="project-address">{project.address}</p> {/* Exibe o endereço */}
                  <label htmlFor={`status-${project.id}`} className="block text-sm font-medium text-gray-700 mt-2">Status:</label>
                    <select
                      id={`status-${project.id}`}
                      name={`status-${project.id}`}
                      className={`mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-indigo-500 sm:text-sm ${getStatusClass(project.status)} ${project.status === '' ? 'bg-purple-300 text-purple-700 border-purple-700' : ''}`}
                      value={project.status}
                      onChange={(e) => handleStatusChange(project.id, e.target.value)}
                    >
                      <option value="" className="text-purple-700">Escolha o status do projeto</option>
                      <option value="Em andamento">Em andamento</option>
                      <option value="Pausado">Pausado</option>
                      <option value="Finalizado">Finalizado</option>
                    </select>
                </div>
                <div className="px-4 py-4 sm:px-6">
                  <Link to={`/projects/${project.id}`} className="memorial">Memorial</Link>

                  <button onClick={() => openProjectDetailsModal(project)} className="details">Detalhes</button>
                  <button onClick={() => handleOpenPdfModal(project)} className="btn-open-pdf-modal"><span className="icon-folder-pdf"></span></button>

                  {/* <button onClick={() => confirmDeleteProject(project.id)} className="delet">Excluir</button> */}
                  <button onClick={() => confirmDeleteProject(project.id)} className="delet"><span className="icon-trash"></span></button>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal para mostrar detalhes do projeto */}
      {selectedProject && modalMode === 'details' && (
  <div className="modal-overlay">
    <div className="modal">
      <button onClick={closeModal} className="modal-close">&times;</button>
      <div className="modal-content">
        <h2 className='details-text'>Detalhes do Projeto</h2>
        <p><strong>Projeto:</strong> {selectedProject.projectName}</p>
        <p><strong>Nome do Cliente:</strong> {selectedProject.companyName}</p>
        <p><strong>CNPJ/CPF:</strong> {selectedProject.cnpj}</p>
        <p><strong>Telefone:</strong> {selectedProject.phone}</p>
        <p><strong>Data de início:</strong> {formatDate(selectedProject.startDate)}</p>
        <p><strong>Data de término:</strong> {formatDate(selectedProject.endDate)}</p>
        <p><strong>Status:</strong> {selectedProject.status}</p>
        <p><strong>CEP:</strong> {selectedProject.cep}</p>
        <p><strong>Endereço:</strong> {selectedProject.address}, {selectedProject.number}</p>
        <p><strong>Bairro:</strong> {selectedProject.neighborhood}</p>
        <p><strong>Cidade:</strong> {selectedProject.city}</p>
        <p className='edit-escopo'><strong>Escopo do projeto:</strong> {selectedProject.projectScope}</p>
        {/* Chame a função openEditModal com o projectId */}
        {openEditModal(selectedProject.id)}
      </div>
    </div>
  </div>
)}
      

      {/* Modal de edição do projeto */}
      {isEditModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button onClick={closeEditModal} className="modal-close">&times;</button>
            <div className="modal-content">
              <InfDetails projectId={selectedProject.id} />
            </div>
          </div>
        </div>
      )}
            {/* Modal para enviar PDF para o projeto */}
            {modalMode === 'pdf' && (
  <div className="modal-overlay">
    <div className="modal">
      <button onClick={() => setModalMode(null)} className="modal-close">&times;</button>
      <div className="modal-content">
        <h2><strong>Enviar PDF para : </strong>{selectedProject && selectedProject.projectName}</h2>
        <input type="file" accept=".pdf" onChange={(e) => setPdfFile(e.target.files[0])} />
        {/* Adicionando campo para dar nome ao PDF */}
        <input type="text" placeholder="Nome do PDF" value={pdfFileName} onChange={(e) => setPdfFileName(e.target.value)} />
        {/* <button onClick={handlePdfUpload}>Enviar</button> */}
        <button onClick={handlePdfUpload} >Enviar <span className="icon-send"></span></button>


        {/* Lista de PDFs enviados */}
        <div>
          <h3>PDFs Enviados:</h3>
          <ul>
            {projectDocuments.map(document => (
              <li key={document.id}>
                <a href={document.url} target="_blank" rel="noopener noreferrer" className='document-file-text'>{document.fileName}</a>
                <button onClick={() => confirmDeletePdf(document.id)} className='delet-pdf'>Excluir</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)}



    </div>

    
  );
}

export default ProjectsPage;